<template>
  <div class="appointment-view">
    <app-header icon="user-injured" title="Paciente" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <div class="patient">
            <form @submit.prevent>
              <div class="row">
                <div class="col-sm-12 col-lg-6 col-xl-6">
                  <div><h2 class="subtitle">Foto</h2></div>
                  <div>
                  <div
                    class="patient__image"
                    @click="onImageClick(patient.photo)"
                  >
                    <img v-if="patient.photo" v-bind:src="patient.photo" />
                  </div>
                  </div>
                  <div>
                    <app-file-picker
                        label="Foto"
                        v-model="patient.photo"
                        :errors="patient.photo"
                        accept="image/*"
                        @fileSelected="onPhotoSelected"
                        @fileDeleted="() => (this.patient.photo = '')"
                    >
                    </app-file-picker>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit-first">
                  <h2 class="subtitle">Informações</h2>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    name="name"
                    required
                    label="Nome"
                    v-model="patient.name"
                  />
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-model="patient.cpf"
                    name="cpf"
                    label="CPF"
                    :mask="MASKS.cpf"
                    v-mask="'###.###.###-##'"
                  />
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-if="patient.user"
                    name="email"
                    label="Email"
                    type="email"
                    required
                    v-model="patient.user.email"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-model="patient.password"
                    type="password"
                    autocomplete="new-password"
                    name="password"
                    label="Senha"
                  ></app-input>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <b-field label="Data de nascimento">
                    <b-datepicker
                      v-model="patient.birthday"
                      :locale="'pt-BR'"
                      :years-range="[-100, 100]"
                      placeholder="Selecionar data..."
                      icon="calendar"
                      trap-focus
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-model="patient.phone"
                    type="text"
                    name="phone"
                    label="Telefone"
                    v-mask="'(##) #########'"
                  ></app-input>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
                  <h2 class="subtitle">Endereço</h2>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    label="CEP"
                    @input="getCepInfo"
                    v-model="patient.cep"
                    type="text"
                    :mask="MASKS.cep"
                    v-mask="'#####-###'"
                  ></app-input>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-model="patient.street"
                    type="text"
                    name="street"
                    label="Rua"
                  ></app-input>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-model="patient.number"
                    type="text"
                    name="number"
                    label="Número"
                  ></app-input>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-model="patient.complement"
                    type="text"
                    name="complement"
                    label="Complemento"
                  ></app-input>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <app-input
                    v-model="patient.neighborhood"
                    type="text"
                    name="neighborhood"
                    label="Bairro"
                  ></app-input>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <b-field label="Cidade">
                    <b-select
                      v-model="patient.city_id"
                      placeholder="Selecionar"
                      :errors="errors.city_id"
                    >
                      <option
                        v-for="city in cities"
                        :key="city.id"
                        :value="city.id"
                      >
                        {{ city.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12 col-lg-6 col-xl-4">
                  <b-field label="Estado">
                    <b-select
                      v-if="patient.city"
                      v-model="patient.city.state_id"
                      placeholder="Selecionar"
                      @input="loadCities(patient.city.state_id)"
                    >
                      <option
                        v-for="state in states"
                        :key="state.id"
                        :value="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </b-select>
                    <b-select
                      v-else
                      v-model="state_id"
                      placeholder="Selecionar"
                      @input="loadCities(state_id)"
                    >
                      <option
                        v-for="state in states"
                        :key="state.id"
                        :value="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="row mt-6">
                <div class="col-12">
                  <b-button
                    :disabled="$v.$invalid"
                    @click="onSubmit()"
                    type="is-primary"
                    >Salvar</b-button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PatientsService from '@/services/patients.service.js';
import StatesService from '@/services/states.service';
import CitiesService from '@/services/cities.service';
import CepService from '@/services/cep.service';
import MASKS from '@/constants/masks.constant';
import * as moment from 'moment';
import { required, email } from 'vuelidate/lib/validators';
import FileMixin from '@/mixins/FileMixin';

export default {
  mixins: [FileMixin],
  data: () => ({
    errors: {},
    MASKS: MASKS,
    isLoading: true,
    isLoadingCity: false,
    title: '',
    patient: {
      city: {
        id: null
      },
      user: {
        email: null
      }
    },
    patientCity: null,
    //patient.city.id
    patients: [],
    states: [],
    cities: [],
    state_id: null,
    city_id: null,
    photoSelected: false
  }),
  validations: {
    patient: {
      name: {
        required,
      },
      user: {
        email: {
          required,
          email,
        },
      },
      birthday: {
        required,
      },
    },
  },
  methods: {
    statesGetter: StatesService.search,
    citiesGetter: CitiesService.search,
    async getCepInfo(cep) {
      if (typeof cep == 'undefined' || cep == null) {
        return;
      }
      cep = cep.replace(/\D/g, '');

      if (!cep || cep.length < 8) return;

      await CepService.getCepInfo(cep).then(({ data }) => {
          CitiesService
            .getId(data.city_id)
            .then(({ data }) => {
              let city = {
                id: '',
                state_id: ''
              };
              if (typeof data.id != 'undefined' && typeof  data.state_id != 'undefined') {
                city = data;
                this.loadCities(city.state_id);
                this.patient = {
                  ...this.patient,
                  city_id: city.id,
                  city: {
                    id: city.id,
                    state_id: city.state_id,
                  }
                };
              }
            })
            .finally(() => (this.isLoading = false));

        this.patient = {
          ...this.patient,
          street: data.logradouro,
          neighborhood: data.bairro,
        };
      });
    },
    loadCities(state_id) {
      this.isLoadingCity = true;
      CitiesService.searchByStateId(state_id)
        .then(({ data }) => (this.cities = data))
        .finally(() => (this.isLoadingCity = false));
    },
    loadStates() {
      this.isLoading = true;
      StatesService.get()
        .then(({ data }) => (this.states = data))
        .finally(() => (this.isLoading = false));
    },
    loadPatient(id) {
      this.isLoading = true;

      if (typeof id != 'undefined') {

        PatientsService.getId(id)
          .then(({ data: data }) => {
            this.patient = data;
            if (this.patient.birthday) {
              this.patient.birthday = new Date(this.patient.birthday);
            }
            typeof this.patient.city != 'undefined' && this.patient.city != null
              ? this.loadCities(this.patient.city.state_id)
              : this.loadStates();
          })
          .finally(() => (this.isLoading = false));
      }

      this.isLoading = false;
    },
    onImageClick(url) {
      if (!url) return;
      window.open(url, '_blank');
    },
    onSubmit() {
      this.errors = {};
      !this.id && this.save();
      this.id &&
        this.update(this.id);
    },
    save() {
      let data = {
        approved: 1,
        cpf: this.patient.cpf?.replace(/\D/g, ''),
        name: this.patient.name,
        email: this.patient.user.email,
        password: this.patient.password,
        birthday: moment(this.patient.birthday).format('YYYY-MM-DD'),
        phone: this.patient.phone?.replace(/\D/g, ''),
        cellphone: this.patient.cellphone?.replace(/\D/g, ''),
        cep: this.patient.cep?.replace(/\D/g, ''),
        street: this.patient.street,
        number: this.patient.number,
        complement: this.patient.complement,
        neighborhood: this.patient.neighborhood,
        city_id: this.patient.city_id,
      };

      if(this.patient.photo){
        data['photo'] = this.patient.photo;
      }

      this.isSaving = true;
      PatientsService.save(data)
          .then(() => {
            this.$buefy.snackbar.open('Paciente atualizado com sucesso!');
            this.$router.push({ name: 'patients.index' });
          })
          .catch(({ response }) => {
            const message = response.data.message;
            this.$buefy.snackbar.open(
                typeof message === 'string'
                    ? message
                    : 'Erro ao tentar atualizar o Paciente.',
                'error'
            );
            this.errors = response.data.message;
          })
          .finally(() => (this.isSaving = false));
    },
    update(id) {
      let data = {
        approved: this.patient.approved,
        cpf: this.patient.cpf?.replace(/\D/g, ''),
        name: this.patient.name,
        email: this.patient.user.email,
        password: this.patient.password,
        birthday: moment(this.patient.birthday).format('YYYY-MM-DD'),
        phone: this.patient.phone?.replace(/\D/g, ''),
        cellphone: this.patient.cellphone?.replace(/\D/g, ''),
        cep: this.patient.cep?.replace(/\D/g, ''),
        street: this.patient.street,
        number: this.patient.number,
        complement: this.patient.complement,
        neighborhood: this.patient.neighborhood,
        city_id: this.patient.city_id,
      };

      if(this.patient.photo && this.photoSelected){
        data['photo'] = this.patient.photo;
      }

      this.isSaving = true;
      PatientsService.update(id, data)
        .then(() => {
          this.$buefy.snackbar.open('Paciente atualizado com sucesso!');
          this.$router.push({ name: 'patients.index' });
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Médico.',
            'error'
          );
          this.errors = response.data.message;
        })
        .finally(() => (this.isSaving = false));
    },
    onPhotoSelected(value) {
      this.fileToBase64(value).then(
          (base64) => (this.patient.photo = base64)
      );
      this.photoSelected = true;
    },
  },

  mounted() {
    const id = this.$route.params.id;
    this.id = this.$route.params.id;
    this.loadPatient(id);
    this.loadStates();
  },
};
</script>

<style lang="scss" scoped>
.patient {
  display: flex;

  &__image {
    margin-right: 24px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    height: 120px;
    width: 100%;
    height: 100%;
    text-align: center;

    img {
      object-fit: cover;
    }
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .links {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
</style>
